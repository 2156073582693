var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"models-update"},[_c('layout',{attrs:{"title":'Edit model'}},[_c('b-btn',{attrs:{"slot":"header-right","variant":"primary"},on:{"click":_vm.back},slot:"header-right"},[_vm._v("Back")]),_c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm","spinner-variant":"primary"}},[_c('b-card',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"Name:","label-for":"form-input-name","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-v","type":"text","state":errors[0] ? false : null,"placeholder":"Name","disabled":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('hr'),(_vm.isUploading)?[_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"cloud-upload","variant":"primary","font-scale":"10","animation":"fade"}}),_c('b-progress',{staticClass:"mt-4",attrs:{"max":_vm.progress.max,"variant":"primary","height":"2rem","striped":true,"animated":true}},[_c('b-progress-bar',{attrs:{"value":_vm.progress.value}},[_c('span',[_vm._v("Progress: "),_c('strong',[_vm._v(_vm._s(((_vm.progress.value / _vm.progress.max) * 100).toFixed(2))+"%")])])])],1),_c('p',{staticClass:"small mt-4"},[_vm._v("Please wait while uploading the image.")])],1)]:[_c('p',{staticClass:"text-center"},[_c('b-img-lazy',_vm._b({attrs:{"rounded":"","alt":_vm.model.name,"src":_vm.heroUrl}},'b-img-lazy',{ blank: true, blankColor: '#777', width: 600, height: 348, class: 'm1' },false))],1),_c('b-form-group',{attrs:{"label":"Image:","label-for":"form-input-hero","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"hero","name":"hero","rules":"required|image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"form-input-resource","state":errors[0] ? false : null,"placeholder":"Choose an hero image or drop it here... (600 x 348)","drop-placeholder":"Drop image here...","accept":"image/jpeg, image/png"},model:{value:(_vm.form.hero),callback:function ($$v) {_vm.$set(_vm.form, "hero", $$v)},expression:"form.hero"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)]],2)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }