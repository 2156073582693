

































import Vue from "vue";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import UserService from "@/services/user";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

for (const [rule, validation] of Object.entries({ required, email })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "ForgotPassword",
    data() {
        return {
            form: {
                email: '',
            },
            success: false,
            overlay: false,
        };
    },
    methods: {
        onSubmit() {
            this.overlay = true;

            UserService.requestPassword(this.form.email).then(value => {
                this.$notify({
                    group: "forgot-password",
                    type: "success",
                    title: this.$appNotificationSuccessTitle,
                    text: "We just send an email with a password reset link."
                });

                this.overlay = false;
                this.success = true;
            }).catch((reason: FailureResponse)  => {
                if (reason.type == ErrorType.Validation && reason.response?.data) {
                    this.observer.setErrors(reason.response?.data);
                } else {
                    this.$notify({
                        group: "forgot-password",
                        type: "error",
                        title: this.$appNotificationErrorTitle,
                        text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                    });
                }

                this.overlay = false;
                this.success = false;
            });
        },
    },
    computed: {
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    }
});
