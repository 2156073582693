import axios from "axios";
import AuthModel from "@/interfaces/Models/auth";
import store from "@/store";
import FailureResponse from "@/interfaces/failureResponse";
import SuccessResponse from "@/interfaces/successResponse";
import ErrorType from "@/enums/errorType";
import PagedResponse from "@/interfaces/pagedResponse";

class StackExchange {
    private readonly baseUrl: string;

    constructor() {
        this.baseUrl = process.env.VUE_APP_API_BASE_URL;
    }

    protected async fetchPaged(url: string, page: number, parameters?: any): Promise<any> {
        try {
            const auth = store.getters.auth as AuthModel | null;
            const _parameters = {
                page: page > 0 ? page : 1
            };

            if(parameters) {
                Object.assign(_parameters, parameters);
            }

            const {data, status} = await axios({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth?.access_token}`
                },
                method: "get",
                url: url,
                params: _parameters,
            })

            if (data.data && data.links && data.meta) {
                return new class implements SuccessResponse<PagedResponse<any>> {
                    status = status;
                    response = data;
                }();
            } else {
                return  Promise.reject(new class implements FailureResponse {
                    status = 0;
                    type = ErrorType.Decoding;
                }());
            }
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    protected async fetch(url: string, parameters?: any): Promise<any> {
        try {
            const auth = store.getters.auth as AuthModel | null;
            const {data, status} = await axios({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth?.access_token}`
                },
                method: "get",
                url: url,
                params: parameters,
            })

            if (data.data) {
                return new class implements SuccessResponse<any> {
                    status = status;
                    response = data.data;
                }();
            } else {
                return  Promise.reject(new class implements FailureResponse {
                    status = 0;
                    type = ErrorType.Decoding;
                }());
            }
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    protected async store(url: string, method: "post"|"put", parameters?: any, expectData = true): Promise<any> {
        try {
            const auth = store.getters.auth as AuthModel | null;
            const {data, status} = await axios({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth?.access_token}`
                },
                method: method,
                url: url,
                data: parameters,
            })

            if (data.data || !expectData) {
                return new class implements SuccessResponse<any> {
                    status = status;
                    response = data.data;
                }();
            } else {
                return  Promise.reject(new class implements FailureResponse {
                    status = 0;
                    type = ErrorType.Decoding;
                }());
            }
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    protected async delete(url: string): Promise<null> {
        try {
            const auth = store.getters.auth as AuthModel | null;
            const {data, status} = await axios({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth?.access_token}`
                },
                method: "delete",
                url: url,
            })

            return null;
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    protected async upload(url: string, method: "post"|"put", parameters?: FormData, onUploadProgress?: any, expectData = true): Promise<any> {
        try {
            const auth = store.getters.auth as AuthModel | null;
            const {data, status} = await axios({
                baseURL: this.baseUrl,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth?.access_token}`
                },
                onUploadProgress: onUploadProgress,
                method: method,
                url: url,
                data: parameters,
            })

            if (data.data || !expectData) {
                return new class implements SuccessResponse<any> {
                    status = status;
                    response = data.data;
                }();
            } else {
                return  Promise.reject(new class implements FailureResponse {
                    status = 0;
                    type = ErrorType.Decoding;
                }());
            }
        } catch (error) {
            return this.errorHandler(error);
        }
    }

    private errorHandler(error:any): Promise<any> {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    store.commit('auth', null);
                    store.commit('user', null);

                    return  Promise.reject(new class implements FailureResponse {
                        status = 401;
                        type = ErrorType.Authentication;
                        response = error.response.data?.error;
                    }());
                case 403:
                    return  Promise.reject(new class implements FailureResponse {
                        status = 403;
                        type = ErrorType.Authorization;
                        response = error.response.data?.error;
                    }());
                case 404:
                    return  Promise.reject(new class implements FailureResponse {
                        status = 404;
                        type = ErrorType.NotFound;
                        response = error.response.data?.error;
                    }());
                case 422:
                    return  Promise.reject(new class implements FailureResponse {
                        status = 422;
                        type = ErrorType.Validation;
                        response = error.response.data?.error;
                    }());
                default:
                    return  Promise.reject(new class implements FailureResponse {
                        status = 0;
                        type = ErrorType.Fatal;
                        response = error.response.data?.error;
                    }());
            }
        }

        return  Promise.reject(new class implements FailureResponse {
            status = 0;
            type = ErrorType.Fatal;
        }());
    }
}

export default StackExchange;
