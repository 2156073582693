import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import AuthModel from "@/interfaces/Models/auth";
import UserModel from "@/interfaces/Models/user";
import CompositionModel from "@/interfaces/Models/composition";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Login",
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: { auth: false }
    },

    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () =>
            import(/* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"),
        meta: { auth: false }
    },

    {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: () =>
            import(/* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"),
        meta: { auth: false }
    },

    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/users",
        name: "UsersIndex",
        component: () =>
            import(/* webpackChunkName: "users-index" */ "../views/users/Index.vue"),
        meta: { auth: true, adminRole: true }
    },

    {
        path: "/users/create",
        name: "UserStore",
        component: () =>
            import(/* webpackChunkName: "users-store" */ "../views/users/Store.vue"),
        meta: { auth: false }
    },

    {
        path: "/users/profile",
        name: "UsersProfile",
        component: () =>
            import(/* webpackChunkName: "users-profile" */ "../views/users/Profile.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/users/:id",
        name: "UsersUpdate",
        component: () =>
            import(/* webpackChunkName: "users-update" */ "../views/users/Update.vue"),
        meta: { auth: false }
    },

    {
        path: "/compositions",
        name: "CompositionsIndex",
        component: () =>
            import(/* webpackChunkName: "compositions-index" */ "../views/compositions/Index.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/compositions/:id",
        name: "CompositionsUpdate",
        component: () =>
            import(/* webpackChunkName: "compositions-update" */ "../views/compositions/Update.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/compositions/:id/recipes",
        name: "RecipesForComposition",
        component: () =>
            import(/* webpackChunkName: "recipes-composition" */ "../views/recipes/Composition.vue"),
        props: true,
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/models",
        name: "ModelsIndex",
        component: () =>
            import(/* webpackChunkName: "models-index" */ "../views/models/Index.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/models/:id",
        name: "ModelsUpdate",
        component: () =>
            import(/* webpackChunkName: "models-update" */ "../views/models/Update.vue"),
        meta: { auth: true, adminRole: false }
    },

    {
        path: "/imports",
        name: "ImportsIndex",
        component: () =>
            import(/* webpackChunkName: "imports-index" */ "../views/imports/Index.vue"),
        meta: { auth: true, adminRole: false },
        redirect: {
            name: "ImportsUpload"
        },
        children: [
            {
                path: "upload",
                name: "ImportsUpload",
                component: () =>
                    import(/* webpackChunkName: "imports-upload" */ "../views/imports/Upload.vue"),
                meta: { auth: true, adminRole: false },
                redirect: {
                    name: "ImportsUploadIndex"
                },
                children: [
                    {
                        path: "index",
                        name: "ImportsUploadIndex",
                        component: () =>
                            import(/* webpackChunkName: "imports-upload-index" */ "../views/imports/upload/Index.vue"),
                        meta: {auth: true, adminRole: false},
                    },
                    {
                        path: ":id/review",
                        name: "ImportsUploadReview",
                        component: () =>
                            import(/* webpackChunkName: "imports-upload-review" */ "../views/imports/upload/Review.vue"),
                        meta: {auth: true, adminRole: false},
                    },
                    {
                        path: ":id/run",
                        name: "ImportsUploadRun",
                        component: () =>
                            import(/* webpackChunkName: "imports-upload-run" */ "../views/imports/upload/Run.vue"),
                        meta: {auth: true, adminRole: false},
                    }
                ],
            },
            {
                path: "history",
                name: "ImportsHistory",
                component: () =>
                    import(/* webpackChunkName: "imports-history" */ "../views/imports/History.vue"),
                meta: { auth: true, adminRole: false }
            },
        ]
    },

    {
        path: "/404",
        name: "NotFound",
        component: () =>
            import(/* webpackChunkName: "notfound" */ "../views/NotFound.vue"),
        meta: { auth: false }
    },

    {
        path: '*',
        redirect: '/404'
    },

];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        const auth = store.getters.auth as AuthModel | undefined;
        if (auth?.access_token) {
            if (to.matched.some(record => record.meta.adminRole)) {
                const user = store.getters.user as UserModel | undefined;

                if (user && user.is_admin) {
                    next();
                } else {
                    next({ name: "NotFound" });
                }
            } else {
                next();
            }
        } else {
            next({ name: "Login" });
        }
    } else {
        next();
    }
});

export default router;
