









import Vue from "vue";

export default Vue.extend({
    name: "Stepper",
    props: {
        step: {
            type: Number,
            required: true
        },
        icons: {
            type: Array,
            required: true
        },
    },
    methods: {
        style(index: number) {
            if (index == 0) {
                return { 'left': '0' };
            }

            if (index == this.totalIcons - 1) {
                return { 'right': '0' };
            }

            return { 'left': (index * 100/(this.totalIcons - 1))+'%' };
        },

        variant(index: number): string {
            if (this._step >= index + 1) {
                return "primary";
            }

            return "secondary";
        }
    },
    computed: {
        totalIcons(): number {
            return this.icons.length;
        },
        _step(): number {
            if (this.step > this.totalIcons) {
                return this.totalIcons;
            }

            if (this.step < 1) {
                return 1;
            }

            return this.step;
        },

        progress(): number {
            return this._step * 100 / this.totalIcons;
        }
    }
});
