






























import Vue from "vue";
import AuthService from "@/services/auth";
import FailureResponse from "@/interfaces/failureResponse";
import UserModel from "@/interfaces/Models/user";

export default Vue.extend({
    name: "Sidebar",
    props: {
        id: {
            type: String,
            required: true
        },
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        change(visible: boolean) {
            this.$store.commit('isSidebarOpen', visible);
        },
        logout() {
            AuthService.logout().then(value => {
                this.$store.commit('auth', null);
                this.$store.commit('user', null);

                this.$router.push({ name: 'Login' });
            }).catch((reason: FailureResponse)  => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            });
        }
    },
    computed: {
        userIsAdmin(): boolean {
            const user =  this.$store.getters.user as UserModel | undefined

            if (user) {
                return user.is_admin;
            }

            return false;
        }
    }
});
