










import Vue from "vue";
import Stepper from "@/components/Stepper.vue";
import {Route} from "vue-router";

export default Vue.extend({
    name: "ImportsUpload",
    components: {
        Stepper,
    },
    created() {
        this.updateStepper(this.$route);
    },
    data() {
        return {
            stepper: {
                icons: [
                    "box-arrow-in-down",
                    "gem",
                    "link"
                ],
                step: 1,
            },
        }
    },
    methods: {
        updateStepper(route: Route) {
            switch (route.name) {
                case "ImportsUploadReview":
                    this.stepper.step = 2;break;
                case "ImportsUploadRun":
                    this.stepper.step = 3;break;
                default:
                    this.stepper.step = 1;break;
            }
        }
    },
    watch:{
        $route (to: Route){
            this.updateStepper(to);
        }
    }
});
