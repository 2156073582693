












import Vue from "vue";
import UserService from "@/services/user";
import UserModel from "@/interfaces/Models/user";
import Layout from "@/components/Layout.vue";
import User from "@/components/User.vue";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

export default Vue.extend({
    name: "UsersProfile",
    components: {
        Layout,
        User
    },
    data() {
        return {
            errors: null,
            overlay: false,
        };
    },
    methods: {
        onSubmit(data: any) {
            if (this.user) {
                this.user.name = data.form.name;
                this.user.email = data.form.email;

                this.overlay = true;

                UserService.update(this.user, data.form.password, data.form.confirmation).then(value => {
                    this.$store.commit('user', value.response);

                    this.$notify({
                        type: "success",
                        title: this.$appNotificationSuccessTitle,
                        text: "Your profile has successfully been updated."
                    });

                    this.overlay = false;
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.errors = reason.response?.data;
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.overlay = false;
                });
            }
        }
    },
    computed: {
        user(): UserModel | null {
            return this.$store.getters.user;
        }
    }
});
