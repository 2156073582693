












































import Vue from "vue";
import ImportService from "@/services/import";
import FailureResponse from "@/interfaces/failureResponse";
import ImportModel from "@/interfaces/Models/import";


export default Vue.extend({
    name: "ImportsUploadRun",
    created() {
        this.overlay = true;
        this.ping();
    },
    beforeDestroy() {
        this.stopPing = true;
        clearTimeout(this.timeOut);
    },
    data() {
        return {
            fields: [
                {
                    key: "model",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "configuration",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "type",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "payload_total",
                    label: "Total row(s)",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
            ],
            progress: {
                value: 0.00,
                max: 0.00
            },
            import: {} as ImportModel,
            timeOut: 0,
            overlay: true,
            isCompleted: false,
            stopPing: false,
        }
    },
    methods: {
         ping() {
            const id = Number(this.$route.params.id);

            ImportService.show(id, ["job_batch"]).then(value => {
                this.import = value.response;

                if (this.import.job_batch)
                {
                    const jobBatch = this.import.job_batch;

                    this.progress.value = jobBatch.total_jobs - jobBatch.pending_jobs;
                    this.progress.max = jobBatch.total_jobs;

                    if (jobBatch.pending_jobs > 0) {
                        if (!this.stopPing)
                        {
                            this.timeOut = setTimeout(this.ping, 2000);
                        }

                        this.isCompleted = false;
                    }
                    else {
                        this.isCompleted = true;
                    }
                }
                this.overlay = false;
            }).catch((reason: FailureResponse)  => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });

                this.overlay = false;
            });
        },
        confirmation() {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to cancel the configuration.", {
                title: "Are you sure?",
                size: "md",
                okVariant: "primary",
                okTitle: "Yes, please",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    ImportService.cancel(this.import).then(() => {
                        this.$notify({
                            type: "success",
                            title: this.$appNotificationSuccessTitle,
                            text: "The configuration has successfully been canceled."
                        });

                        this.stopPing = true;
                        clearTimeout(this.timeOut);
                        this.$router.push({ name: "ImportsIndex" });

                        this.overlay = false;
                    }).catch((reason: FailureResponse)  => {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.overlay = false;
                    });
                }
            });
        },
    },
});
