













import Vue from "vue";
import UserService from "@/services/user";
import UserModel from "@/interfaces/Models/user";
import Layout from "@/components/Layout.vue";
import User from "@/components/User.vue";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

export default Vue.extend({
    name: "UsersUpdate",
    components: {
        Layout,
        User,
    },
    created() {
        const id = Number(this.$route.params.id);

        this.overlay = true;
        UserService.show(id).then(value => {
            this.user = value.response;
            this.overlay = false;
        }).catch((reason: FailureResponse)  => {
            this.$notify({
                type: "error",
                title: this.$appNotificationErrorTitle,
                text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
            });

            this.overlay = false;
        });
    },
    data() {
        return {
            user: {} as UserModel,
            errors: null,
            overlay: false,
        }
    },
    methods: {
        onSubmit(data: any) {
            if (this.user) {
                this.user.name = data.form.name;
                this.user.email = data.form.email;
                // eslint-disable-next-line @typescript-eslint/camelcase
                this.user.is_admin = data.form.isAdmin;
                this.overlay = true;

                UserService.update(this.user, data.form.password, data.form.confirmation).then(value => {
                    this.$notify({
                        type: "success",
                        title: this.$appNotificationSuccessTitle,
                        text: "The user has successfully been updated."
                    });

                    this.overlay = false;
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.errors = reason.response?.data;
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }

                    this.overlay = false;
                });
            }
        },
        back() {
            this.$router.back();
        }
    }
});
