import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import AuthModel from "@/interfaces/Models/auth";


export default new class extends StackExchangeService {
    async login(email: string, password: string): Promise<SuccessResponse<AuthModel>> {
        return await this.store("auth/login", "post", {
            email, password
        });
    }

    async logout(): Promise<null> {
        return await this.delete("auth/logout");
    }
}
