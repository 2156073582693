




















































































































import Vue from "vue";
import RecipeService from "@/services/recipe";
import CompositionModel from "@/interfaces/Models/composition";
import RecipeModel from "@/interfaces/Models/recipe";
import LinksModel from "@/interfaces/Models/links";
import MetaModel from "@/interfaces/Models/meta";
import Layout from "@/components/Layout.vue";
import FailureResponse from "@/interfaces/failureResponse";


export default Vue.extend({
    name: "RecipesForComposition",
    components: {
        Layout
    },
    props: {
        composition: Object,
    },
    created() {
        this.showAll(1);
    },
    data() {
        return {
            overview: {
                fields: [
                    {
                        key: "id",
                        label: '#',
                        thStyle: {width: "10px"},
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "lmi",
                        label: 'LMI',
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "model",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "configuration",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                ]
            },
            recipes: {
                fields: [
                    {
                        key: "id",
                        label: '#',
                        thStyle: {width: "10px"},
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "boom_length",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "radius",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "pitch",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "roll",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "articulation_angle",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "rated_capacity",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "min_fal",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "max_fal",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "boom_angle",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "total_height",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                    {
                        key: "max_hook_height",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                ],
            },

            loading: false,
            skeleton: {
                show:false,
                rows: 0
            },
            data: [] as RecipeModel[],
            links: null as LinksModel | null,
            meta: null as MetaModel | null,
        }
    },
    methods: {
        showAll(page: number) {
            const compositionId = Number(this.$route.params.id);

           if (this.meta) {
               this.skeleton.show = true;
           } else {
               this.loading = true;
           }

            RecipeService.showAllForComposition(compositionId, page).then(value => {
                this.data = value.response.data;
                this.links = value.response.links;
                this.meta = value.response.meta;

                this.loading = false;
                this.skeleton.show = false;
                this.skeleton.rows = this.data.length;
            }).catch((reason: FailureResponse)  => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            });
        },
        change(page: number) {
            if (this.meta) {
                if (this.meta.current_page  < page) {
                    this.skeleton.rows = (this.meta.to ?? 0) + this.meta.per_page <= this.meta.total ? this.meta.per_page : this.meta.total - (this.meta.to ?? 0);
                } else {
                    this.skeleton.rows = (this.meta.from ?? 0) - this.meta.per_page >= 0 ? this.meta.per_page : (this.meta.from ?? 0);
                }
            }

            this.showAll(page);
        },
        back() {
            this.$router.back();
        }
    },
    computed: {
        _composition(): CompositionModel {
            if (this.data.length) {
                this.data[0].composition;
            }

            return this.composition;
        }
    },
});
