var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('img',{staticClass:"img-fluid mb-4",attrs:{"src":require("../assets/logo-white.png"),"alt":_vm.$appName}}),_c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm","spinner-variant":"primary"}},[_c('b-card',{attrs:{"title":"Please log in"}},[_c('hr'),_c('b-form-group',{attrs:{"label":"Email address:","label-for":"form-input-email","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-email","type":"email","state":errors[0] ? false : null,"required":"","placeholder":"Email address"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password:","label-for":"form-input-password","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-password","type":"password","state":errors[0] ? false : null,"required":"","placeholder":"Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary"}},[_vm._v("Login")])],1)],1),_c('router-link',{staticClass:"login__forgot",attrs:{"to":{ name: 'ForgotPassword' }}},[_vm._v("Forgot your password?")])],1)]}}])}),_c('notifications',{attrs:{"group":"login","duration":_vm.$appNotificationDuration,"position":_vm.$appNotificationPosition}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }