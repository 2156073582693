var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('img',{staticClass:"img-fluid mb-4",attrs:{"src":require("../assets/logo-white.png"),"alt":_vm.$appName}}),_c('b-overlay',{attrs:{"show":_vm.overlay,"rounded":"sm","spinner-variant":"primary"}},[_c('b-card',{attrs:{"title":"Reset password"}},[_c('hr'),(_vm.success)?[_c('b-icon',{attrs:{"icon":"patch-check","variant":"primary","font-scale":"10","animation":"throb"}}),_c('p',{staticClass:"small mt-4"},[_vm._v("The password has been updated.")])]:[_c('b-form-group',{attrs:{"label":"Email address:","label-for":"form-input-email","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-email","type":"email","state":errors[0] ? false : null,"required":"","placeholder":"Email address"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password:","label-for":"form-input-password","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-password","type":"password","state":errors[0] ? false : null,"required":"","placeholder":"Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm password:","label-for":"form-input-confirmation","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"confirmation","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-confirmation","type":"password","state":errors[0] ? false : null,"placeholder":"Confirm password"},model:{value:(_vm.form.confirmation),callback:function ($$v) {_vm.$set(_vm.form, "confirmation", $$v)},expression:"form.confirmation"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","block":"","variant":"primary"}},[_vm._v("Update")])]],2)],1),_c('router-link',{staticClass:"reset-password__back",attrs:{"to":{ name: 'Login' }}},[_vm._v("Back to login")])],1)]}}])}),_c('notifications',{attrs:{"group":"reset-password","duration":_vm.$appNotificationDuration,"position":_vm.$appNotificationPosition}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }