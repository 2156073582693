













import Vue from "vue";
import UserService from "@/services/user";
import UserModel from "@/interfaces/Models/user";
import Layout from "@/components/Layout.vue";
import User from "@/components/User.vue";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

export default Vue.extend({
    name: "UsersStore",
    components: {
        Layout,
        User,
    },
    data() {
        return {
            errors: null,
            overlay: false,
        }
    },
    methods: {
        onSubmit(data: any) {
            this.overlay = true;

            UserService.create(data.form.email,
                data.form.name,
                data.form.password,
                data.form.confirmation,
                data.form.isAdmin
            ).then(value => {
                this.$notify({
                    type: "success",
                    title: this.$appNotificationSuccessTitle,
                    text: "The user has successfully been created."
                });

                this.overlay = false;
                this.back();
            }).catch((reason: FailureResponse)  => {
                if (reason.type == ErrorType.Validation && reason.response?.data) {
                    this.errors = reason.response?.data;
                } else {
                    this.$notify({
                        type: "error",
                        title: this.$appNotificationErrorTitle,
                        text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                    });
                }

                this.overlay = false;
            });
        },
        back() {
            this.$router.back();
        }
    }
});
