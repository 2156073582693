































import Vue from "vue";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import AuthService from "@/services/auth";
import UserService from "@/services/user";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

for (const [rule, validation] of Object.entries({ required, email })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "Login",
    data() {
        return {
            form: {
                email: '',
                password: '',
            },
            overlay: false,
        };
    },
    methods: {
        onSubmit() {
            this.overlay = true;
            AuthService.login(this.form.email, this.form.password).then(value => {
                this.$store.commit('auth', value.response);
                UserService.fetchAuthenticated().then(value1 => {
                    this.$store.commit('user', value1.response);

                    this.overlay = false;
                    this.$router.push({ name: 'Dashboard' });
                }).catch((reason: FailureResponse)  => {
                    this.$notify({
                        group: "login",
                        type: "error",
                        title: this.$appNotificationErrorTitle,
                        text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                    });

                    this.overlay = false;
                });
            }).catch((reason: FailureResponse)  => {
                if (reason.type == ErrorType.Validation && reason.response?.data) {
                    this.observer.setErrors(reason.response?.data);
                } else {
                    if (reason.type == ErrorType.Authentication) {
                        this.$notify({
                            group: "login",
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: "Your credentials are incorrect."
                        });
                    } else {
                        this.$notify({
                            group: "login",
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });
                    }
                }

                this.overlay = false;
            })
        },
    },
    computed: {
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    }
});
