



























































import Vue from "vue";
import * as moment from "moment";
import ImportService from "@/services/import";
import FailureResponse from "@/interfaces/failureResponse";
import ImportModel from "@/interfaces/Models/import";
import LinksModel from "@/interfaces/Models/links";
import MetaModel from "@/interfaces/Models/meta";
import ImportStatus from "@/enums/importStatus";

export default Vue.extend({
    name: "ImportsHistory",
    created() {
        this.showAll(1);
    },
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: '#',
                    thStyle: {width: "10px"},
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "model",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "lmi",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "configuration",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "type",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                },
                {
                    key: "payload_total",
                    label: "Total row(s)",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "115px"},
                },
                {
                    key: "user",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "90px"},
                },
                {
                    key: "updated_at",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "250px"},
                },
                {
                    key: "status",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "90px"},
                },
                {
                    key: "actions",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "115px"},
                },
            ],
            loading: false,
            skeleton: {
                show:false,
                rows: 0
            },
            data: [] as ImportModel[],
            links: null as LinksModel | null,
            meta: null as MetaModel | null,
        }
    },
    methods: {
        showAll(page: number) {
            if (this.meta) {
                this.skeleton.show = true;
            } else {
                this.loading = true;
            }

            ImportService.showAll(page).then(value => {
                this.data = value.response.data;
                this.links = value.response.links;
                this.meta = value.response.meta;

                this.loading = false;
                this.skeleton.show = false;
                this.skeleton.rows = this.data.length;
            }).catch((reason: FailureResponse) => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            });
        },
        change(page: number) {
            if (this.meta) {
                if (this.meta.current_page  < page) {
                    this.skeleton.rows = (this.meta.to ?? 0) + this.meta.per_page <= this.meta.total ? this.meta.per_page : this.meta.total - (this.meta.to ?? 0);
                } else {
                    this.skeleton.rows = (this.meta.from ?? 0) - this.meta.per_page >= 0 ? this.meta.per_page : (this.meta.from ?? 0);
                }
            }

            this.showAll(page);
        },
        confirmation(index: number) {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete the import.", {
                title: "Are you sure?",
                size: "md",
                okVariant: "primary",
                okTitle: "Yes, please",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    this.skeleton.show = true;

                    const _import = this.data[index];

                    ImportService.destroy(_import).then(value1 => {
                        this.data.splice(index, 1);

                        this.$notify({
                            type: "success",
                            title: this.$appNotificationSuccessTitle,
                            text: "The import has successfully been deleted."
                        });

                        this.skeleton.show = false;
                    }).catch((reason: FailureResponse)  => {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.skeleton.show = false;
                    });
                }
            });
        },
    },
    computed: {
        ImportStatus: () => ImportStatus,
        moment: () => moment
    }
});
