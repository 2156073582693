
















































import Vue from "vue";
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import UserModel from "@/interfaces/Models/user";

for (const [rule, validation] of Object.entries({ required, email, confirmed })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "User",
    props: {
        user: Object,
        errors: Object,
        forAdmin: {
            type: Boolean,
            required: true,
        },
        showBtnCancel: {
            type: Boolean,
            required: true,
        },
        forcePasswordUpdate: {
            type: Boolean,
            required: false,
        },
    },
    created() {
        const _user = this.user as UserModel | null;
        this.form.name = _user?.name ?? '';
        this.form.email = _user?.email ?? '';
        this.form.isAdmin = _user?.is_admin ?? false;
        this.form.updatePassword = this.forcePasswordUpdate;
    },
    data() {
        return {
            form: {
                name: '',
                email: '',
                password: '',
                confirmation: '',
                isAdmin: false,
                updatePassword: false,
            },
        };
    },
    methods: {
        onSubmit() {
            this.$emit('submit', this.$data);
        },
        back() {
            this.$router.back();
        }
    },
    computed: {
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    },
    watch: {
        user: function (value: UserModel | null){
            this.form.name = value?.name ?? '';
            this.form.email = value?.email ?? '';
            this.form.isAdmin = value?.is_admin ?? false;
        },
        'form.updatePassword': function (value) {
            if (!value) {
                this.form.password = '';
                this.form.confirmation = '';
            }
        },
        errors: function (value) {
            if (value) {
                this.observer.setErrors(value);
            }
        }
    }
});
