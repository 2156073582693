



















import Vue from "vue";
import AuthService from "@/services/auth";
import UserModel from "@/interfaces/Models/user";
import FailureResponse from "@/interfaces/failureResponse";

export default Vue.extend({
    name: "Header",
    props: {
        sidebarId: {
            type: String,
            required: true
        }
    },
    methods: {
        logout() {
            AuthService.logout().then(value => {
                this.$store.commit('auth', null);
                this.$store.commit('user', null);

                this.$router.push({ name: 'Login' });
            }).catch((reason: FailureResponse)  => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            });
        }
    },
    computed: {
        userName(): string {
            const user =  this.$store.getters.user as UserModel | undefined

            if (user) {
                return user.name && user.name?.length !== 0 ? user.name as string : user.email;
            }

            return "User";
        }
    }
});
