


































import Vue from "vue";
import ImportService from "@/services/import";
import FailureResponse from "@/interfaces/failureResponse";
import ImportModel from "@/interfaces/Models/import";
import {BvTableField} from "bootstrap-vue/src/components/table";


export default Vue.extend({
    name: "ImportsUploadReview",
    created() {
        const id = Number(this.$route.params.id);

        this.overlay = true;
        ImportService.show(id).then(value => {
            this.import = value.response;

            const payload =  JSON.parse(this.import.payload_sample);
            if (payload)
            {
                Object.keys(payload).forEach((row) => {
                    if (parseInt(row))
                    {
                        this.payload.items.push(payload[row]);
                    } else {
                        Object.keys(payload[row]).forEach((column) => {
                            const tableField = {
                                key: column,
                                label: payload[row][column],
                                thClass: "align-middle",
                                tdClass: "align-middle"
                            };

                            this.payload.fields.push(tableField);
                        });
                    }
                });
            }
            this.overlay = false;
            this.showBtnSubmit = true;
        }).catch((reason: FailureResponse)  => {
            this.$notify({
                type: "error",
                title: this.$appNotificationErrorTitle,
                text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
            });

            this.overlay = false;
            this.showBtnSubmit = false;
        });
    },
    data() {
        return {
            overview: {
                fields: [
                    {
                        key: "model",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "lmi",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "configuration",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "type",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "payload_total",
                        label: "Total row(s)",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                ]
            },
            payload: {
                fields: [] as BvTableField[],
                items: [] as string[]
            },
            import: {} as ImportModel,
            showBtnSubmit: false,
            overlay: true,
        }
    },
    methods: {
        confirmation() {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to import the CSV.", {
                title: "Are you sure?",
                size: "md",
                okVariant: "primary",
                okTitle: "Yes, please",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    this.overlay = true;
                    ImportService.run(this.import).then(() => {
                        this.$router.push({ name: 'ImportsUploadRun', params: { id: `${this.import.id}` } });
                        this.overlay = false;
                    }).catch((reason: FailureResponse)  => {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.overlay = false;
                    });
                }
            });
        },
        back() {
            this.$router.back();
        }
    },
});
