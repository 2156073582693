











import Vue from "vue";
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import Content from "@/components/Content.vue";

export default Vue.extend({
    name: "Layout",
    components: {
        Header,
        Sidebar,
        Content
    },
    props: {
        title: String,
        subTitle: String
    },
    data() {
        return {
            sidebar: {
                id: "sidebar",
                isOpen: false,
            }
        };
    },
    computed: {
        isSidebarOpen(): boolean {
            return this.$store.getters.isSidebarOpen;
        }
    }
});
