







































import Vue from "vue";
import { extend } from "vee-validate";
import {ext, required} from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import ImportService from "@/services/import";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

for (const [rule, validation] of Object.entries({ ext, required })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "ImportsUploadIndex",
    created() {
        this.overlay = true;
        ImportService.showInProgress().then(value => {
            this.$router.push({ name: 'ImportsUploadRun', params: { id: `${value.response.id}` } });
            this.overlay = false;
        }).catch(()  => {
            this.overlay = false;
        });
    },
    data() {
        return {
            form: {
                resource: null as File | null,
            },
            progress: {
                value: 0.00,
                max: 0.00
            },
            isUploading: false,
            overlay: false
        }
    },
    methods: {
        onSubmit() {
            if (this.form.resource)
            {
                this.isUploading = true;
                this.progress.value = 0.00;
                this.progress.max = 0.00;

                ImportService.create(this.form.resource, (progressEvent: ProgressEvent) => {
                    this.progress.value = progressEvent.loaded;
                    this.progress.max = progressEvent.total;
                }).then(value => {
                    this.$router.push({ name: 'ImportsUploadReview', params: { id: `${value.response.id}` } });

                    this.$notify({
                        type: "success",
                        title: this.$appNotificationSuccessTitle,
                        text: "The CSV file has successfully been uploaded."
                    });
                }).catch((reason: FailureResponse)  => {
                    this.$notify({
                        type: "error",
                        title: this.$appNotificationErrorTitle,
                        text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                    });

                    this.isUploading = false;
                });
            }
        },
    },
    computed: {
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    },
});
