export default new class {
    setLocal(name: string, value: any): string | null {
        name = this.prefixName(name);
        value = this.valueStringify(value);

        if (value) {
            localStorage.setItem(name, value);
        } else {
            localStorage.removeItem(name);
        }

        return value;
    }

    getLocal(name: string): string | null {
        name = this.prefixName(name);

        return localStorage.getItem(name);
    }

    setCookie(name: string, value: any, ttl: number): string {
        name = this.prefixName(name);
        value = this.valueStringify(value);

        if (!value) {
            ttl = 0;
        }

        const d = new Date();
        d.setTime(d.getTime() + (ttl * 1000));
        const expires = `expires=${d.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/;SameSite=Strict`;

        return value;
    }

    getCookie(name: string): string | null {
        name = `${this.prefixName(name)}=`;

        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return null;
    }

    private prefixName(name: string): string {
        return `_${process.env.VUE_APP_STORAGE_PREFIX}_${name}`;
    }

    private valueStringify(value: any): string {
        if (value && typeof value !== "string") {
            value = JSON.stringify(value);
        }

        return value;
    }
}
