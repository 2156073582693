/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import PagedResponse from "@/interfaces/pagedResponse";
import Composition from "@/interfaces/Models/composition";



export default new class extends StackExchangeService {
    async showAll(page: number): Promise<SuccessResponse<PagedResponse<Composition>>> {
        return await this.fetchPaged("compositions", page, {
            includes: ["lmi", "model", "configuration", "type"]
        });
    }

    async createGraphic(id: number, graphic: File, onUploadProgress?: any): Promise<SuccessResponse<Composition>> {
        const formData = new FormData();
        formData.append('graphic', graphic);

        return await this.upload(`compositions/${id}/graphic`, "post", formData, onUploadProgress);
    }

    async show(id: number) : Promise<SuccessResponse<Composition>> {
        return await this.fetch(`compositions/${id}`, {
            includes: ["lmi", "model", "configuration", "type"]
        });
    }

    async destroy(composition: Composition): Promise<null> {
        return await this.delete(`compositions/${composition.id}`);
    }
}
