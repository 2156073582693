
















import Vue from "vue";
import Layout from "@/components/Layout.vue";


export default Vue.extend({
    name: "ImportsIndex",
    components: {
        Layout
    }
});
