/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import PagedResponse from "@/interfaces/pagedResponse";
import ModelModel from "@/interfaces/Models/model";



export default new class extends StackExchangeService {
    async showAll(page: number, search: string): Promise<SuccessResponse<PagedResponse<ModelModel>>> {
        if (search && search.length > 0) {
            return await this.fetchPaged("models", page, {
                "sort[0][key]":  "name",
                "sort[0][direction]": "asc",
                "filter_groups[0][filters][0][key]": "name",
                "filter_groups[0][filters][0][operator]": "sw",
                "filter_groups[0][filters][0][value]": search,
            });
        }

        return await this.fetchPaged("models", page);
    }

    async createHero(id: number, hero: File, onUploadProgress?: any): Promise<SuccessResponse<ModelModel>> {
        const formData = new FormData();
        formData.append('hero', hero);

        return await this.upload(`models/${id}/hero`, "post", formData, onUploadProgress);
    }

    async show(id: number) : Promise<SuccessResponse<ModelModel>> {
        return await this.fetch(`models/${id}`);
    }

    async destroy(model: ModelModel): Promise<null> {
        return await this.delete(`models/${model.id}`);
    }
}
