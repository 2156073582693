import Vue from "vue";
import Vuex from "vuex";
import StorageService from "@/services/storage";
import AuthModel from "@/interfaces/models/auth";
import UserModel from "@/interfaces/models/user";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isSidebarOpen: true,
        user: StorageService.getLocal("user"),
        auth: StorageService.getCookie("auth"),
    },
    mutations: {
        isSidebarOpen(state, value) {
            state.isSidebarOpen = value;
        },
        auth(state, auth: AuthModel | null) {
            state.auth = StorageService.setCookie("auth", auth, auth?.expires_in ?? 0);
        },
        user(state, user: UserModel | null) {
            state.user = StorageService.setLocal("user", user);
        }
    },
    getters: {
        isSidebarOpen: state => state.isSidebarOpen,
        auth: state => {
            if (state.auth) {
                return JSON.parse(state.auth)
            }

            return null;
        },
        user: state => {
            if (state.user) {
                return JSON.parse(state.user)
            }

            return null;
        }
    }
});
