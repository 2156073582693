/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import PagedResponse from "@/interfaces/pagedResponse";
import Recipe from "@/interfaces/Models/recipe";



export default new class extends StackExchangeService {
    async showAllForComposition(compositionId: number, page: number): Promise<SuccessResponse<PagedResponse<Recipe>>> {
        return await this.fetchPaged(`compositions/${compositionId}/recipes`, page, {
            includes: ["composition", "pitch", "roll", "articulation_angle", "boom_length", "radius"]
        });
    }
}
