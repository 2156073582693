


































































import Vue from "vue";
import ModelService from "@/services/model";
import ModelModel from "@/interfaces/Models/model";
import LinksModel from "@/interfaces/Models/links";
import MetaModel from "@/interfaces/Models/meta";
import Layout from "@/components/Layout.vue";
import FailureResponse from "@/interfaces/failureResponse";


export default Vue.extend({
    name: "ModelsIndex",
    components: {
        Layout
    },
    created() {
        this.showAll(1);
    },
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: '#',
                    thStyle: {width: "10px"},
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "hero_url",
                    label: '',
                    thClass:    "align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "75px"},
                },
                {
                    key: "name",
                    thClass:"align-middle",
                    tdClass: "align-middle"
                },
                {
                    key: "actions",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                    thStyle: {width: "115px"},
                },
            ],
            loading: false,
            skeleton: {
                show:false,
                rows: 0
            },
            form: {
                search: '',
            },
            data: [] as ModelModel[],
            links: null as LinksModel | null,
            meta: null as MetaModel | null,
        }
    },
    methods: {
        showAll(page: number) {
           if (this.meta) {
               this.skeleton.show = true;
           } else {
               this.loading = true;
           }

            ModelService.showAll(page, this.form.search).then(value => {
                this.data = value.response.data;
                this.links = value.response.links;
                this.meta = value.response.meta;

                this.loading = false;
                this.skeleton.show = false;
                this.skeleton.rows = this.data.length;
            }).catch((reason: FailureResponse)  => {
                this.$notify({
                    type: "error",
                    title: this.$appNotificationErrorTitle,
                    text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                });
            });
        },
        change(page: number) {
            if (this.meta) {
                if (this.meta.current_page  < page) {
                    this.skeleton.rows = (this.meta.to ?? 0) + this.meta.per_page <= this.meta.total ? this.meta.per_page : this.meta.total - (this.meta.to ?? 0);
                } else {
                    this.skeleton.rows = (this.meta.from ?? 0) - this.meta.per_page >= 0 ? this.meta.per_page : (this.meta.from ?? 0);
                }
            }

            this.showAll(page);
        },
        confirmation(index: number) {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete the model.", {
                title: "Are you sure?",
                size: "md",
                okVariant: "primary",
                okTitle: "Yes, please",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    this.skeleton.show = true;

                    const model = this.data[index];

                    ModelService.destroy(model).then(value1 => {
                        this.data.splice(index, 1);

                        this.$notify({
                            type: "success",
                            title: this.$appNotificationSuccessTitle,
                            text: "The model has successfully been deleted."
                        });

                        this.skeleton.show = false;
                    }).catch((reason: FailureResponse)  => {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.skeleton.show = false;
                    });
                }
            });
        },
        onSubmit() {
            this.showAll(1);
        },
        onReset() {
            this.form.search = '';
        }
    }
});
