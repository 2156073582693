var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.onSubmit)}}},[_c('b-form-group',{attrs:{"label":"Name:","label-for":"form-input-name","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-v","type":"text","state":errors[0] ? false : null,"placeholder":"Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email address*:","label-for":"form-input-email","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-email","type":"email","state":errors[0] ? false : null,"required":"","placeholder":"Email address*"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.forcePasswordUpdate)?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","button-variant":"primary","size":"lg","switch":""},model:{value:(_vm.form.updatePassword),callback:function ($$v) {_vm.$set(_vm.form, "updatePassword", $$v)},expression:"form.updatePassword"}},[_vm._v(" Update password? ")])],1):_vm._e(),(_vm.form.updatePassword)?[_c('b-form-group',{attrs:{"label":"Password*:","label-for":"form-input-password","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-password","type":"password","state":errors[0] ? false : null,"required":"","placeholder":"Password*"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm password*:","label-for":"form-input-confirmation","label-class":"sr-only"}},[_c('validation-provider',{attrs:{"vid":"confirmation","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"form-input-confirmation","type":"password","state":errors[0] ? false : null,"placeholder":"Confirm password*"},model:{value:(_vm.form.confirmation),callback:function ($$v) {_vm.$set(_vm.form, "confirmation", $$v)},expression:"form.confirmation"}}),_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),(_vm.forAdmin)?_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","button-variant":"primary","size":"lg","switch":""},model:{value:(_vm.form.isAdmin),callback:function ($$v) {_vm.$set(_vm.form, "isAdmin", $$v)},expression:"form.isAdmin"}},[_vm._v(" Admin? ")])],1):_vm._e(),_c('div',[(_vm.showBtnCancel)?_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.back}},[_vm._v("Cancel")]):_vm._e(),_vm._v(" "),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Submit")])],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }