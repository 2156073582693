

















































import Vue from "vue";
import { extend } from "vee-validate";
import {image, required} from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import ModelService from "@/services/model";
import ModelModel from "@/interfaces/Models/model";
import Layout from "@/components/Layout.vue";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";
import UserModel from "@/interfaces/Models/user";

for (const [rule, validation] of Object.entries({ image, required })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "ModelsUpdate",
    components: {
        Layout
    },
    created() {
        const id = Number(this.$route.params.id);

        this.overlay = true;
        ModelService.show(id).then(value => {
            this.model = value.response;
            this.overlay = false;
        }).catch((reason: FailureResponse)  => {
            this.$notify({
                type: "error",
                title: this.$appNotificationErrorTitle,
                text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
            });

            this.overlay = false;
        });
    },
    data() {
        return {
            model: {} as ModelModel,
            form: {
                name: '',
                hero: null as File | null,
            },
            progress: {
                value: 0.00,
                max: 0.00
            },
            isUploading: false,
            overlay: false,
        };
    },
    methods: {
        onSubmit() {
            if (this.form.hero)
            {
                this.isUploading = true;
                this.progress.value = 0.00;
                this.progress.max = 0.00;

                ModelService.createHero(this.model.id, this.form.hero, (progressEvent: ProgressEvent) => {
                    this.progress.value = progressEvent.loaded;
                    this.progress.max = progressEvent.total;
                }).then(value => {
                    this.model = value.response;

                    this.$notify({
                        type: "success",
                        title: this.$appNotificationSuccessTitle,
                        text: "The image has successfully been uploaded."
                    });

                    this.isUploading = false;
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.isUploading = false;
                    }
                });
            }
        },
        back() {
            this.$router.back();
        }
    },
    computed: {
        heroUrl(): string {
            if (this.model && this.model.hero_url) {
                return this.model.hero_url
            }

            return '';
        },
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    },
    watch: {
        model: function (value: ModelModel | null) {
            this.form.name = value?.name ?? '';
        },
    }
});
