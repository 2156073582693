














































































import Vue from "vue";
import { extend } from "vee-validate";
import {image, required} from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import CompositionService from "@/services/composition";
import CompositionModel from "@/interfaces/Models/composition";
import Layout from "@/components/Layout.vue";
import FailureResponse from "@/interfaces/failureResponse";
import ErrorType from "@/enums/errorType";

for (const [rule, validation] of Object.entries({ image, required })) {
    extend(rule, {...validation, message: (messages as { [key: string]: any })[rule]});
}

export default Vue.extend({
    name: "CompositionsUpdate",
    components: {
        Layout
    },
    created() {
        const id = Number(this.$route.params.id);

        this.overlay = true;
        CompositionService.show(id).then(value => {
            this.composition = value.response;
            this.overlay = false;
        }).catch((reason: FailureResponse)  => {
            this.$notify({
                type: "error",
                title: this.$appNotificationErrorTitle,
                text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
            });

            this.overlay = false;
        });
    },
    data() {
        return {
            overview: {
                fields: [
                    {
                        key: "id",
                        label: '#',
                        thStyle: {width: "10px"},
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "lmi",
                        label: 'LMI',
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "model",
                        thClass:"align-middle",
                        tdClass: "align-middle"
                    },
                    {
                        key: "configuration",
                        thClass:"align-middle",
                        tdClass: "align-middle",
                    },
                  {
                    key: "type",
                    thClass:"align-middle",
                    tdClass: "align-middle",
                  },
                ]
            },
            composition: {} as CompositionModel,
            form: {
                graphic: null as File | null,
            },
            progress: {
                value: 0.00,
                max: 0.00
            },
            isUploading: false,
            overlay: false,
        };
    },
    methods: {
        onSubmit() {
            if (this.form.graphic)
            {
                this.isUploading = true;
                this.progress.value = 0.00;
                this.progress.max = 0.00;

                CompositionService.createGraphic(this.composition.id, this.form.graphic, (progressEvent: ProgressEvent) => {
                    this.progress.value = progressEvent.loaded;
                    this.progress.max = progressEvent.total;
                }).then(value => {
                    this.composition = value.response;

                    this.$notify({
                        type: "success",
                        title: this.$appNotificationSuccessTitle,
                        text: "The image has successfully been uploaded."
                    });

                    this.isUploading = false;
                }).catch((reason: FailureResponse)  => {
                    if (reason.type == ErrorType.Validation && reason.response?.data) {
                        this.observer.setErrors(reason.response?.data);
                    } else {
                        this.$notify({
                            type: "error",
                            title: this.$appNotificationErrorTitle,
                            text: (reason.response?.message && reason.response?.message.length !== 0 ? reason.response?.message : reason.type)
                        });

                        this.isUploading = false;
                    }
                });
            }
        },
        back() {
            this.$router.back();
        }
    },
    computed: {
        graphicUrl(): string {
            if (this.composition && this.composition.graphic_url) {
                return this.composition.graphic_url
            }

            return '';
        },
        observer(): Vue & { setErrors: (errors: any) => boolean } {
            return this.$refs.observer as Vue & { setErrors: (errors: any) => boolean }
        }
    }
});
