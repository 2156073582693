/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import ImportModel from "@/interfaces/Models/import";
import PagedResponse from "@/interfaces/pagedResponse";
import ImportStatus from "@/enums/importStatus";



export default new class extends StackExchangeService {
    async create(resource: File, onUploadProgress?: any): Promise<SuccessResponse<ImportModel>> {
        const formData = new FormData();
        formData.append('resource', resource);

        return await this.upload("imports", "post", formData, onUploadProgress);
    }

    async showAll(page: number): Promise<SuccessResponse<PagedResponse<ImportModel>>> {
        return await this.fetchPaged("imports", page, {
            includes: ["user"],
            "filter_groups[0][filters][0][key]": "status",
            "filter_groups[0][filters][0][operator]": "eq",
            "filter_groups[0][filters][0][value]": ImportStatus.InProgress,
            "filter_groups[0][filters][0][not]": true,
            "sort[0][key]": "updated_at",
            "sort[0][direction]": "desc"
        });
    }

    async show(id: number, includes?: string[]) : Promise<SuccessResponse<ImportModel>> {
        return await this.fetch(`imports/${id}`, {
            includes
        });
    }

    async showInProgress() : Promise<SuccessResponse<ImportModel>> {
        return await this.fetch("imports/in-progress");
    }

    async run(_import: ImportModel): Promise<SuccessResponse<ImportModel>> {
        return await this.store(`imports/${_import.id}/run`, "put");
    }

    async cancel(_import: ImportModel): Promise<null> {
        return await this.delete(`imports/${_import.id}/cancel`);
    }

    async destroy(_import: ImportModel): Promise<null> {
        return await this.delete(`imports/${_import.id}`);
    }
}
