/* eslint-disable @typescript-eslint/camelcase */
import StackExchangeService from "./stackExchange";
import SuccessResponse from "@/interfaces/successResponse";
import PagedResponse from "@/interfaces/pagedResponse";
import UserModel from "@/interfaces/Models/user";



export default new class extends StackExchangeService {
    async requestPassword(email: string): Promise<SuccessResponse<null>> {
        return await this.store("users/request-password", "post", {
            email
        }, false);
    }

    async resetPassword(email: string, password: string, password_confirmation: string, token: string): Promise<SuccessResponse<null>> {
        return await this.store("users/reset-password", "put", {
            email,
            password,
            password_confirmation,
            token,
        }, false);
    }

    async showAll(page: number, search: string): Promise<SuccessResponse<PagedResponse<UserModel>>> {
        if (search && search.length > 0) {
            return await this.fetchPaged("users", page, {
                "sort[0][key]":  "email",
                "sort[0][direction]": "asc",
                "sort[1][key]":  "name",
                "sort[1][direction]": "asc",
                "filter_groups[0][filters][0][key]": "email",
                "filter_groups[0][filters][0][operator]": "sw",
                "filter_groups[0][filters][0][value]": search,
                "filter_groups[0][or]": "1",
                "filter_groups[0][filters][1][key]": "name",
                "filter_groups[0][filters][1][operator]": "sw",
                "filter_groups[0][filters][1][value]": search,
            });
        }

        return await this.fetchPaged("users", page);
    }

    async show(id: number) : Promise<SuccessResponse<UserModel>> {
        return await this.fetch(`users/${id}`);
    }

    async fetchAuthenticated(): Promise<SuccessResponse<UserModel>> {
        return await this.fetch("users/authenticated");
    }

    async create(email: string, name: string | null, password: string, password_confirmation: string, is_admin: boolean): Promise<SuccessResponse<UserModel>> {

        return await this.store("users", "post", {
            email,
            name,
            password,
            password_confirmation,
            is_admin,
        });
    }

    async update(user: UserModel, password?: string, password_confirmation?: string): Promise<SuccessResponse<UserModel>> {
        const parameters = {
            name: user.name,
            email: user.email,
            is_admin: user.is_admin,
        };

        if (password) {
            Object.assign(parameters, {
                password,
                password_confirmation
            });
        }

        return await this.store(`users/${user.id}`, "put", parameters);
    }

    async destroy(user: UserModel): Promise<null> {
        return await this.delete(`users/${user.id}`);
    }
}
